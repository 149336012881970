const openPopupNews = elw => {
    document.getElementById(elw).classList.add('active')
}
const closePopupNews = elw => {
    elw = typeof elw !== 'undefined' ?  elw : "popup-news";
    document.getElementById(elw).classList.remove('active')
    sessionStorage.setItem('popup_news', 'hide')
}
window.addEventListener('load', () => {
    if (document.getElementById('popup-news') && sessionStorage.getItem('popup_news') !== 'hide') {
        openPopupNews('popup-news')
    }
})

window.addEventListener('keyup', ew => {
    ew.key === "Escape" && closePopupNews('popup-news')
})

window.addEventListener('click', ew => {
    ew.target.id === "popup-news" && closePopupNews()
})