function openPopup() {
    var modal = document.getElementById('popup')
    modal.classList.add('active')
    
}
function closePopup() {
    var modal = document.getElementById('popup')
    modal.classList.remove('active')
    
}

window.addEventListener('keyup', function(e) {
    var modal = document.getElementById('popup')
    if(e.key === "Escape"){
        closePopup()
    }
})

window.addEventListener('click', function(e){
    var modal = document.getElementById('popup')
    e.target.id === "popup" && closePopup()
})

// Popup-Belotero und Popup-Bocouture und Popup-Bild
function openPopupBelotero() {
    var modalBelotero = document.getElementById('popup-Belotero')
    modalBelotero.classList.add('active')
    
}
function closePopupBelotero() {
    var modalBelotero = document.getElementById('popup-Belotero')
    modalBelotero.classList.remove('active')
    
}

window.addEventListener('keyup', function(e) {
    var modalBelotero = document.getElementById('popup-Belotero')
    if(e.key === "Escape"){
        closePopupBelotero()
    }
})

window.addEventListener('click', function(e){
    var modalBelotero = document.getElementById('popup-Belotero')
    e.target.id === "popup-Belotero" && closePopupBelotero()
})



function openPopupBocouture() {
    var modalBocouture = document.getElementById('popup-Bocouture')
    modalBocouture.classList.add('active')
    
}
function closePopupBocouture() {
    var modalBocouture = document.getElementById('popup-Bocouture')
    modalBocouture.classList.remove('active')
    
}

window.addEventListener('keyup', function(e) {
    var modalBocouture = document.getElementById('popup-Bocouture')
    if(e.key === "Escape"){
        closePopupBocouture()
    }
})

window.addEventListener('click', function(e){
    var modalBocouture = document.getElementById('popup-Bild')
    e.target.id === "popup-Bild" && closePopupBocouture()
})



function openPopupBild() {
    var modalBelotero = document.getElementById('popup-Bild')
    modalBelotero.classList.add('active')
    
}
function closePopupBild() {
    var modalBelotero = document.getElementById('popup-Bild')
    modalBelotero.classList.remove('active')
    
}

window.addEventListener('keyup', function(e) {
    var modalBelotero = document.getElementById('popup-Bild')
    if(e.key === "Escape"){
        closePopupBild()
    }
})

window.addEventListener('click', function(e){
    var modalBelotero = document.getElementById('popup-Bild')
    e.target.id === "popup-Bild" && closePopupBild()
})


// Angebot
function openPopupAngebot() {
    var modalAngebot = document.getElementById('popup-angebot')
    modalAngebot.classList.add('active')
    
}
function closePopupAngebot() {
    var modalAngebot = document.getElementById('popup-angebot')
    modalAngebot.classList.remove('active')
    
}

window.addEventListener('keyup', function(e) {
    var modalAngebot = document.getElementById('popup-angebot')
    if(e.key === "Escape"){
        closePopupAngebot()
    }
})

window.addEventListener('click', function(e){
    var modalAngebot = document.getElementById('popup-angebot')
    e.target.id === "popup-angebot" && closePopupAngebot()
})



    